import {
  startStimulusApp,
  registerControllers
} from "vite-plugin-symfony/stimulus/helpers";

const app = startStimulusApp();
registerControllers(app, import.meta.glob('./controllers/*_(lazy)\?controller.[jt]s(x)\?'));

app.registerActionOption('confirm', ({event, element}) => {
  if (element.getAttribute('data-confirm-message')) {
    var decision = window.confirm(element.getAttribute('data-confirm-message'));
    if (!decision) {
      event.preventDefault();
      return false;
    }
  }
  return true;
});

// app.debug = false;

document.addEventListener("turbo:before-fetch-request", (event) => {
  if (event.target.dataset["turboStream"] === "false") {
    event.detail.fetchOptions.headers["Accept"] = "text/html, application/xhtml+xml"
  }
});

